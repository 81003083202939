/* tslint:disable */
/* eslint-disable */
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddDeliveryAddressReq
 */
export interface AddDeliveryAddressReq {
    /**
     * 
     * @type {boolean}
     * @memberof AddDeliveryAddressReq
     */
    'defaultAddress'?: boolean;
    /**
     * 
     * @type {DeliveryAddressVo}
     * @memberof AddDeliveryAddressReq
     */
    'userAddress'?: DeliveryAddressVo;
}
/**
 * 
 * @export
 * @interface AddressVo
 */
export interface AddressVo {
    /**
     * 
     * @type {string}
     * @memberof AddressVo
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressVo
     */
    'addressDetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressVo
     */
    'zipCode'?: string;
}
/**
 * 
 * @export
 * @interface CouponDto
 */
export interface CouponDto {
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    'couponId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    'couponName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    'couponAmountType'?: CouponDtoCouponAmountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    'couponAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    'validDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    'couponValidStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    'couponValidEndDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    'minimumPurchaseAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    'maximumDiscountAmount'?: number;
}

export const CouponDtoCouponAmountTypeEnum = {
    Percent: 'PERCENT',
    Fare: 'FARE'
} as const;

export type CouponDtoCouponAmountTypeEnum = typeof CouponDtoCouponAmountTypeEnum[keyof typeof CouponDtoCouponAmountTypeEnum];

/**
 * 
 * @export
 * @interface CreateOffersProductOffer
 */
export interface CreateOffersProductOffer {
    /**
     * 
     * @type {number}
     * @memberof CreateOffersProductOffer
     */
    'productOptionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOffersProductOffer
     */
    'itemQuantity'?: number;
}
/**
 * 
 * @export
 * @interface CreateOffersProductReq
 */
export interface CreateOffersProductReq {
    /**
     * 
     * @type {Array<CreateOffersProductOffer>}
     * @memberof CreateOffersProductReq
     */
    'offerList'?: Array<CreateOffersProductOffer>;
}
/**
 * 
 * @export
 * @interface CreateOrderOfferOptionDto
 */
export interface CreateOrderOfferOptionDto {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderOfferOptionDto
     */
    'offerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderOfferOptionDto
     */
    'productOptionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderOfferOptionDto
     */
    'itemQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderOfferOptionDto
     */
    'purchaseAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderOfferOptionDto
     */
    'couponId'?: number;
}
/**
 * 
 * @export
 * @interface CreateOrderOfferProductDto
 */
export interface CreateOrderOfferProductDto {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderOfferProductDto
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderOfferProductDto
     */
    'deliveryFeeAmount'?: number;
    /**
     * 
     * @type {Array<CreateOrderOfferOptionDto>}
     * @memberof CreateOrderOfferProductDto
     */
    'optionList'?: Array<CreateOrderOfferOptionDto>;
}
/**
 * 
 * @export
 * @interface CreateOrderOfferReq
 */
export interface CreateOrderOfferReq {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderOfferReq
     */
    'userAddressId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderOfferReq
     */
    'deliveryMessage'?: string;
    /**
     * 
     * @type {Array<CreateOrderOfferProductDto>}
     * @memberof CreateOrderOfferReq
     */
    'productList'?: Array<CreateOrderOfferProductDto>;
}
/**
 * 
 * @export
 * @interface CreateOrderOfferRes
 */
export interface CreateOrderOfferRes {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderOfferRes
     */
    'orderNumber'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrderProductOrderItemDto
 */
export interface CreateOrderProductOrderItemDto {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderProductOrderItemDto
     */
    'productOptionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderProductOrderItemDto
     */
    'itemQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderProductOrderItemDto
     */
    'purchaseAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderProductOrderItemDto
     */
    'couponId'?: number;
}
/**
 * 
 * @export
 * @interface CreateOrderProductReq
 */
export interface CreateOrderProductReq {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderProductReq
     */
    'userAddressId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderProductReq
     */
    'deliveryMessage'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderProductReq
     */
    'deliveryFeeAmount'?: number;
    /**
     * 
     * @type {Array<CreateOrderProductOrderItemDto>}
     * @memberof CreateOrderProductReq
     */
    'orderItemList'?: Array<CreateOrderProductOrderItemDto>;
}
/**
 * 
 * @export
 * @interface CreateOrderProductRes
 */
export interface CreateOrderProductRes {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderProductRes
     */
    'orderNumber'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserInquiryReq
 */
export interface CreateUserInquiryReq {
    /**
     * 
     * @type {number}
     * @memberof CreateUserInquiryReq
     */
    'orderItemId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInquiryReq
     */
    'userInquiryType'?: CreateUserInquiryReqUserInquiryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserInquiryReq
     */
    'content'?: string;
}

export const CreateUserInquiryReqUserInquiryTypeEnum = {
    Exchange: 'EXCHANGE',
    Return: 'RETURN'
} as const;

export type CreateUserInquiryReqUserInquiryTypeEnum = typeof CreateUserInquiryReqUserInquiryTypeEnum[keyof typeof CreateUserInquiryReqUserInquiryTypeEnum];

/**
 * 
 * @export
 * @interface DeliveryAddressVo
 */
export interface DeliveryAddressVo {
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressVo
     */
    'receiverName'?: string;
    /**
     * 
     * @type {AddressVo}
     * @memberof DeliveryAddressVo
     */
    'address'?: AddressVo;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressVo
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface DeliveryVo
 */
export interface DeliveryVo {
    /**
     * 
     * @type {string}
     * @memberof DeliveryVo
     */
    'deliveryCompanyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryVo
     */
    'deliveryNumber'?: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'errorType'?: ErrorResponseErrorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'errorMessage'?: string;
}

export const ErrorResponseErrorTypeEnum = {
    NotFound: 'NOT_FOUND',
    AuthenticationError: 'AUTHENTICATION_ERROR',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    InvalidImage: 'INVALID_IMAGE',
    UserNameDuplicate: 'USER_NAME_DUPLICATE',
    UserDeactivated: 'USER_DEACTIVATED',
    UserSuspended: 'USER_SUSPENDED',
    UserAlreadySignIn: 'USER_ALREADY_SIGN_IN',
    UserAlreadyCuratedToday: 'USER_ALREADY_CURATED_TODAY',
    UserInquiryAlreadyReplied: 'USER_INQUIRY_ALREADY_REPLIED',
    InvalidCouponPurchaseAmount: 'INVALID_COUPON_PURCHASE_AMOUNT',
    InvalidCouponStatus: 'INVALID_COUPON_STATUS',
    InvalidCouponDate: 'INVALID_COUPON_DATE',
    InvalidCouponUser: 'INVALID_COUPON_USER',
    InvalidCreateOrderRequest: 'INVALID_CREATE_ORDER_REQUEST',
    InvalidCreateOrderPurchaseAmount: 'INVALID_CREATE_ORDER_PURCHASE_AMOUNT',
    InvalidOrderPaymentAlreadyProcess: 'INVALID_ORDER_PAYMENT_ALREADY_PROCESS',
    InvalidOrderPaymentAlreadyCompleted: 'INVALID_ORDER_PAYMENT_ALREADY_COMPLETED',
    InvalidOrderPaymentAlreadyFailed: 'INVALID_ORDER_PAYMENT_ALREADY_FAILED',
    InvalidOrderPaymentAlreadyRefund: 'INVALID_ORDER_PAYMENT_ALREADY_REFUND',
    InvalidPaymentOrderPurchaseAmount: 'INVALID_PAYMENT_ORDER_PURCHASE_AMOUNT',
    InvalidCancelOrderItemStatus: 'INVALID_CANCEL_ORDER_ITEM_STATUS',
    InvalidProductState: 'INVALID_PRODUCT_STATE',
    InvalidProductInventoryQuantity: 'INVALID_PRODUCT_INVENTORY_QUANTITY',
    AlreadyInPromotion: 'ALREADY_IN_PROMOTION',
    FailedPaymentBelowMinimumAmount: 'FAILED_PAYMENT_BELOW_MINIMUM_AMOUNT',
    FailedPaymentDiscoIssue: 'FAILED_PAYMENT_DISCO_ISSUE',
    FailedPaymentExceedAmount: 'FAILED_PAYMENT_EXCEED_AMOUNT',
    FailedPayment: 'FAILED_PAYMENT',
    FailedPaymentInvalidCardExpiration: 'FAILED_PAYMENT_INVALID_CARD_EXPIRATION',
    FailedPaymentInvalidCardNumber: 'FAILED_PAYMENT_INVALID_CARD_NUMBER',
    FailedPaymentInvalidInstallmentMonth: 'FAILED_PAYMENT_INVALID_INSTALLMENT_MONTH',
    FailedPaymentInvalidStoppedCard: 'FAILED_PAYMENT_INVALID_STOPPED_CARD',
    FailedPaymentNotAvailableTime: 'FAILED_PAYMENT_NOT_AVAILABLE_TIME',
    FailedPaymentNotAvailableAmount: 'FAILED_PAYMENT_NOT_AVAILABLE_AMOUNT',
    FailedPaymentRejectCard: 'FAILED_PAYMENT_REJECT_CARD',
    DuplicateSeq: 'DUPLICATE_SEQ',
    DuplicateTagName: 'DUPLICATE_TAG_NAME',
    DuplicateCategoryName: 'DUPLICATE_CATEGORY_NAME',
    DeleteCategoryProductExists: 'DELETE_CATEGORY_PRODUCT_EXISTS',
    InvalidGenderType: 'INVALID_GENDER_TYPE',
    RequiredFashionStyleAssessmentBeforeCurating: 'REQUIRED_FASHION_STYLE_ASSESSMENT_BEFORE_CURATING',
    DuplicateStaffId: 'DUPLICATE_STAFF_ID',
    InvalidDeliveryNumber: 'INVALID_DELIVERY_NUMBER'
} as const;

export type ErrorResponseErrorTypeEnum = typeof ErrorResponseErrorTypeEnum[keyof typeof ErrorResponseErrorTypeEnum];

/**
 * 
 * @export
 * @interface GetAllPromotionProductListRes
 */
export interface GetAllPromotionProductListRes {
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetAllPromotionProductListRes
     */
    'productList'?: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface GetBrandDetailProductDto
 */
export interface GetBrandDetailProductDto {
    /**
     * 
     * @type {number}
     * @memberof GetBrandDetailProductDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailProductDto
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailProductDto
     */
    'productImageUrl'?: string;
    /**
     * 
     * @type {ProductDtoProductYear}
     * @memberof GetBrandDetailProductDto
     */
    'inventoryYear'?: ProductDtoProductYear;
    /**
     * 
     * @type {number}
     * @memberof GetBrandDetailProductDto
     */
    'discountPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBrandDetailProductDto
     */
    'finalAmount'?: number;
}
/**
 * 
 * @export
 * @interface GetBrandDetailRes
 */
export interface GetBrandDetailRes {
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailRes
     */
    'brandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailRes
     */
    'brandLogoImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBrandDetailRes
     */
    'brandDescription'?: string;
    /**
     * 
     * @type {Array<GetBrandDetailProductDto>}
     * @memberof GetBrandDetailRes
     */
    'brandProductList'?: Array<GetBrandDetailProductDto>;
}
/**
 * 
 * @export
 * @interface GetCuratedProductDto
 */
export interface GetCuratedProductDto {
    /**
     * 
     * @type {number}
     * @memberof GetCuratedProductDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCuratedProductDto
     */
    'productImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCuratedProductDto
     */
    'productName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCuratedProductDto
     */
    'discountPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCuratedProductDto
     */
    'finalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCuratedProductDto
     */
    'curatingProductType'?: GetCuratedProductDtoCuratingProductTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetCuratedProductDto
     */
    'inventoryQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCuratedProductDto
     */
    'availableCouponQuantity'?: number;
}

export const GetCuratedProductDtoCuratingProductTypeEnum = {
    Preferred: 'PREFERRED',
    Discovered: 'DISCOVERED'
} as const;

export type GetCuratedProductDtoCuratingProductTypeEnum = typeof GetCuratedProductDtoCuratingProductTypeEnum[keyof typeof GetCuratedProductDtoCuratingProductTypeEnum];

/**
 * 
 * @export
 * @interface GetFashionStyleAssessmentAnswerDto
 */
export interface GetFashionStyleAssessmentAnswerDto {
    /**
     * 
     * @type {number}
     * @memberof GetFashionStyleAssessmentAnswerDto
     */
    'fashionStyleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetFashionStyleAssessmentAnswerDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFashionStyleAssessmentAnswerDto
     */
    'answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFashionStyleAssessmentAnswerDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetFashionStyleAssessmentDto
 */
export interface GetFashionStyleAssessmentDto {
    /**
     * 
     * @type {number}
     * @memberof GetFashionStyleAssessmentDto
     */
    'fashionStyleAssessmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetFashionStyleAssessmentDto
     */
    'question'?: string;
    /**
     * 
     * @type {Array<GetFashionStyleAssessmentAnswerDto>}
     * @memberof GetFashionStyleAssessmentDto
     */
    'answers'?: Array<GetFashionStyleAssessmentAnswerDto>;
}
/**
 * 
 * @export
 * @interface GetFashionStyleAssessmentRes
 */
export interface GetFashionStyleAssessmentRes {
    /**
     * 
     * @type {Array<GetFashionStyleAssessmentDto>}
     * @memberof GetFashionStyleAssessmentRes
     */
    'questionList'?: Array<GetFashionStyleAssessmentDto>;
}
/**
 * 
 * @export
 * @interface GetFashionStyleProductListRes
 */
export interface GetFashionStyleProductListRes {
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetFashionStyleProductListRes
     */
    'productList'?: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface GetFashionStyleRes
 */
export interface GetFashionStyleRes {
    /**
     * 
     * @type {string}
     * @memberof GetFashionStyleRes
     */
    'fashionStyleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFashionStyleRes
     */
    'fashionStyleImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFashionStyleRes
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface GetHomeBannerDto
 */
export interface GetHomeBannerDto {
    /**
     * 
     * @type {string}
     * @memberof GetHomeBannerDto
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHomeBannerDto
     */
    'hrefUrl'?: string;
}
/**
 * 
 * @export
 * @interface GetHomeNotificationRes
 */
export interface GetHomeNotificationRes {
    /**
     * 
     * @type {Array<NotificationDto>}
     * @memberof GetHomeNotificationRes
     */
    'notificationList'?: Array<NotificationDto>;
}
/**
 * 
 * @export
 * @interface GetHomePromotionDto
 */
export interface GetHomePromotionDto {
    /**
     * 
     * @type {number}
     * @memberof GetHomePromotionDto
     */
    'promotionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetHomePromotionDto
     */
    'promotionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHomePromotionDto
     */
    'promotionLogoUrl'?: string;
}
/**
 * 
 * @export
 * @interface GetHomeRes
 */
export interface GetHomeRes {
    /**
     * 
     * @type {string}
     * @memberof GetHomeRes
     */
    'userName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetHomeRes
     */
    'hasNewNotification'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetHomeRes
     */
    'fashionStyleId'?: number;
    /**
     * 
     * @type {Array<GetHomeBannerDto>}
     * @memberof GetHomeRes
     */
    'bannerList'?: Array<GetHomeBannerDto>;
    /**
     * 
     * @type {Array<GetHomePromotionDto>}
     * @memberof GetHomeRes
     */
    'promotionList'?: Array<GetHomePromotionDto>;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetHomeRes
     */
    'recommendProductList'?: Array<ProductDto>;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetHomeRes
     */
    'discoPickProductList'?: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface GetHomeSearchRecentRes
 */
export interface GetHomeSearchRecentRes {
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetHomeSearchRecentRes
     */
    'productList'?: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface GetHomeSearchRes
 */
export interface GetHomeSearchRes {
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetHomeSearchRes
     */
    'result'?: Array<ProductDto>;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetHomeSearchRes
     */
    'recommendProductList'?: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface GetOfferListRes
 */
export interface GetOfferListRes {
    /**
     * 
     * @type {Array<GetUserCartOfferDto>}
     * @memberof GetOfferListRes
     */
    'offerList'?: Array<GetUserCartOfferDto>;
    /**
     * 
     * @type {Array<GetUserCartOfferProductDeliveryDto>}
     * @memberof GetOfferListRes
     */
    'productDeliveryList'?: Array<GetUserCartOfferProductDeliveryDto>;
}
/**
 * 
 * @export
 * @interface GetProductDetailBrandDto
 */
export interface GetProductDetailBrandDto {
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailBrandDto
     */
    'brandId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailBrandDto
     */
    'brandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailBrandDto
     */
    'brandLogoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailBrandDto
     */
    'brandDescription'?: string;
}
/**
 * 
 * @export
 * @interface GetProductDetailDeliveryDto
 */
export interface GetProductDetailDeliveryDto {
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailDeliveryDto
     */
    'feeAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailDeliveryDto
     */
    'deliveryType'?: GetProductDetailDeliveryDtoDeliveryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailDeliveryDto
     */
    'expectedDate'?: string;
}

export const GetProductDetailDeliveryDtoDeliveryTypeEnum = {
    Disco: 'DISCO',
    Other: 'OTHER'
} as const;

export type GetProductDetailDeliveryDtoDeliveryTypeEnum = typeof GetProductDetailDeliveryDtoDeliveryTypeEnum[keyof typeof GetProductDetailDeliveryDtoDeliveryTypeEnum];

/**
 * 
 * @export
 * @interface GetProductDetailOptionDto
 */
export interface GetProductDetailOptionDto {
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailOptionDto
     */
    'productOptionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailOptionDto
     */
    'productOptionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailOptionDto
     */
    'productOptionQuantity'?: number;
}
/**
 * 
 * @export
 * @interface GetProductDetailRes
 */
export interface GetProductDetailRes {
    /**
     * 
     * @type {ProductDto}
     * @memberof GetProductDetailRes
     */
    'product'?: ProductDto;
    /**
     * 
     * @type {ProductDtoProductYear}
     * @memberof GetProductDetailRes
     */
    'inventoryYear'?: ProductDtoProductYear;
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailRes
     */
    'inventoryQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'productSaleStatus'?: GetProductDetailResProductSaleStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailRes
     */
    'discountAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailRes
     */
    'additionalDiscountAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'curatingProductType'?: GetProductDetailResCuratingProductTypeEnum;
    /**
     * 
     * @type {GetProductDetailDeliveryDto}
     * @memberof GetProductDetailRes
     */
    'delivery'?: GetProductDetailDeliveryDto;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'materialDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'colorDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'sizeDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'manufacturerDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'cautionDescription'?: string;
    /**
     * 
     * @type {GetProductDetailResProductionYearMonth}
     * @memberof GetProductDetailRes
     */
    'productionYearMonth'?: GetProductDetailResProductionYearMonth;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'warrantyDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'contactDescription'?: string;
    /**
     * 
     * @type {GetProductDetailBrandDto}
     * @memberof GetProductDetailRes
     */
    'brand'?: GetProductDetailBrandDto;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'companyPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'companyEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'companyAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'businessRegistrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailRes
     */
    'orderSalesRegistrationNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductDetailRes
     */
    'productionDetailImageUrlList'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailRes
     */
    'defaultProductOptionId'?: number;
    /**
     * 
     * @type {Array<GetProductDetailOptionDto>}
     * @memberof GetProductDetailRes
     */
    'productOptionList'?: Array<GetProductDetailOptionDto>;
}

export const GetProductDetailResProductSaleStatusEnum = {
    Available: 'AVAILABLE',
    SoldOut: 'SOLD_OUT',
    Discontinued: 'DISCONTINUED'
} as const;

export type GetProductDetailResProductSaleStatusEnum = typeof GetProductDetailResProductSaleStatusEnum[keyof typeof GetProductDetailResProductSaleStatusEnum];
export const GetProductDetailResCuratingProductTypeEnum = {
    Preferred: 'PREFERRED',
    Discovered: 'DISCOVERED'
} as const;

export type GetProductDetailResCuratingProductTypeEnum = typeof GetProductDetailResCuratingProductTypeEnum[keyof typeof GetProductDetailResCuratingProductTypeEnum];

/**
 * 
 * @export
 * @interface GetProductDetailResProductionYearMonth
 */
export interface GetProductDetailResProductionYearMonth {
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailResProductionYearMonth
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductDetailResProductionYearMonth
     */
    'month'?: GetProductDetailResProductionYearMonthMonthEnum;
    /**
     * 
     * @type {number}
     * @memberof GetProductDetailResProductionYearMonth
     */
    'monthValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductDetailResProductionYearMonth
     */
    'leapYear'?: boolean;
}

export const GetProductDetailResProductionYearMonthMonthEnum = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: 'APRIL',
    May: 'MAY',
    June: 'JUNE',
    July: 'JULY',
    August: 'AUGUST',
    September: 'SEPTEMBER',
    October: 'OCTOBER',
    November: 'NOVEMBER',
    December: 'DECEMBER'
} as const;

export type GetProductDetailResProductionYearMonthMonthEnum = typeof GetProductDetailResProductionYearMonthMonthEnum[keyof typeof GetProductDetailResProductionYearMonthMonthEnum];

/**
 * 
 * @export
 * @interface GetPromotionDetailRes
 */
export interface GetPromotionDetailRes {
    /**
     * 
     * @type {string}
     * @memberof GetPromotionDetailRes
     */
    'promotionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPromotionDetailRes
     */
    'promotionImageUrl'?: string;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetPromotionDetailRes
     */
    'productList'?: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface GetUserCartOfferDto
 */
export interface GetUserCartOfferDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferDto
     */
    'offerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferDto
     */
    'brandId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserCartOfferDto
     */
    'brandName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserCartOfferDto
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserCartOfferDto
     */
    'productImageUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferDto
     */
    'productOptionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserCartOfferDto
     */
    'productOptionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferDto
     */
    'itemAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferDto
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferDto
     */
    'itemQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferDto
     */
    'inventoryQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserCartOfferDto
     */
    'productStateType'?: GetUserCartOfferDtoProductStateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUserCartOfferDto
     */
    'itemAmountValidEndDateTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserCartOfferDto
     */
    'deleted'?: boolean;
}

export const GetUserCartOfferDtoProductStateTypeEnum = {
    Available: 'AVAILABLE',
    SoldOut: 'SOLD_OUT',
    Discontinued: 'DISCONTINUED'
} as const;

export type GetUserCartOfferDtoProductStateTypeEnum = typeof GetUserCartOfferDtoProductStateTypeEnum[keyof typeof GetUserCartOfferDtoProductStateTypeEnum];

/**
 * 
 * @export
 * @interface GetUserCartOfferProductDeliveryDto
 */
export interface GetUserCartOfferProductDeliveryDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferProductDeliveryDto
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserCartOfferProductDeliveryDto
     */
    'deliveryAmount'?: number;
}
/**
 * 
 * @export
 * @interface GetUserCouponListRes
 */
export interface GetUserCouponListRes {
    /**
     * 
     * @type {Array<CouponDto>}
     * @memberof GetUserCouponListRes
     */
    'couponList'?: Array<CouponDto>;
}
/**
 * 
 * @export
 * @interface GetUserCuratedProductListRes
 */
export interface GetUserCuratedProductListRes {
    /**
     * 
     * @type {Array<GetCuratedProductDto>}
     * @memberof GetUserCuratedProductListRes
     */
    'limitedProductList'?: Array<GetCuratedProductDto>;
}
/**
 * 
 * @export
 * @interface GetUserDeliveryAddressQDto
 */
export interface GetUserDeliveryAddressQDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserDeliveryAddressQDto
     */
    'deliveryAddressId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserDeliveryAddressQDto
     */
    'receiverName'?: string;
    /**
     * 
     * @type {AddressVo}
     * @memberof GetUserDeliveryAddressQDto
     */
    'address'?: AddressVo;
    /**
     * 
     * @type {string}
     * @memberof GetUserDeliveryAddressQDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDeliveryAddressQDto
     */
    'defaultAddress'?: boolean;
}
/**
 * 
 * @export
 * @interface GetUserDeliveryAddressRes
 */
export interface GetUserDeliveryAddressRes {
    /**
     * 
     * @type {Array<GetUserDeliveryAddressQDto>}
     * @memberof GetUserDeliveryAddressRes
     */
    'deliveryAddressList'?: Array<GetUserDeliveryAddressQDto>;
}
/**
 * 
 * @export
 * @interface GetUserInfoRes
 */
export interface GetUserInfoRes {
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoRes
     */
    'profileImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoRes
     */
    'nickName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoRes
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoRes
     */
    'recommendCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInfoRes
     */
    'hasFashionStyleId'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserInfoRes
     */
    'userFashionStyleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoRes
     */
    'registrationType'?: GetUserInfoResRegistrationTypeEnum;
}

export const GetUserInfoResRegistrationTypeEnum = {
    Kakao: 'KAKAO',
    Google: 'GOOGLE',
    Apple: 'APPLE'
} as const;

export type GetUserInfoResRegistrationTypeEnum = typeof GetUserInfoResRegistrationTypeEnum[keyof typeof GetUserInfoResRegistrationTypeEnum];

/**
 * 
 * @export
 * @interface GetUserInquiryDto
 */
export interface GetUserInquiryDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserInquiryDto
     */
    'inquiryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'inquiryType'?: GetUserInquiryDtoInquiryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'inquiryStatusType'?: GetUserInquiryDtoInquiryStatusTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'inquiryContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'answer'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'brandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'productImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'productOptionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserInquiryDto
     */
    'itemQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserInquiryDto
     */
    'purchaseAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserInquiryDto
     */
    'createDate'?: string;
}

export const GetUserInquiryDtoInquiryTypeEnum = {
    Exchange: 'EXCHANGE',
    Return: 'RETURN'
} as const;

export type GetUserInquiryDtoInquiryTypeEnum = typeof GetUserInquiryDtoInquiryTypeEnum[keyof typeof GetUserInquiryDtoInquiryTypeEnum];
export const GetUserInquiryDtoInquiryStatusTypeEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED'
} as const;

export type GetUserInquiryDtoInquiryStatusTypeEnum = typeof GetUserInquiryDtoInquiryStatusTypeEnum[keyof typeof GetUserInquiryDtoInquiryStatusTypeEnum];

/**
 * 
 * @export
 * @interface GetUserInquiryListRes
 */
export interface GetUserInquiryListRes {
    /**
     * 
     * @type {Array<GetUserInquiryDto>}
     * @memberof GetUserInquiryListRes
     */
    'inquiryList'?: Array<GetUserInquiryDto>;
}
/**
 * 
 * @export
 * @interface GetUserOrderDetailBrandDto
 */
export interface GetUserOrderDetailBrandDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserOrderDetailBrandDto
     */
    'brandName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserOrderDetailBrandDto
     */
    'totalPurchaseAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserOrderDetailBrandDto
     */
    'totalDeliveryAmount'?: number;
    /**
     * 
     * @type {Array<UserOrderItemDetailDto>}
     * @memberof GetUserOrderDetailBrandDto
     */
    'orderItemList'?: Array<UserOrderItemDetailDto>;
    /**
     * 
     * @type {number}
     * @memberof GetUserOrderDetailBrandDto
     */
    'totalAmount'?: number;
}
/**
 * 
 * @export
 * @interface GetUserOrderDetailPaymentDto
 */
export interface GetUserOrderDetailPaymentDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserOrderDetailPaymentDto
     */
    'discountAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserOrderDetailPaymentDto
     */
    'paymentAmount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserOrderDetailPaymentDto
     */
    'couponNameList'?: Array<string>;
    /**
     * 
     * @type {GetUserOrderDetailPaymentMethodDto}
     * @memberof GetUserOrderDetailPaymentDto
     */
    'paymentMethod'?: GetUserOrderDetailPaymentMethodDto;
}
/**
 * 
 * @export
 * @interface GetUserOrderDetailPaymentMethodCardDto
 */
export interface GetUserOrderDetailPaymentMethodCardDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserOrderDetailPaymentMethodCardDto
     */
    'cardName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserOrderDetailPaymentMethodCardDto
     */
    'installmentMonths'?: number;
}
/**
 * 
 * @export
 * @interface GetUserOrderDetailPaymentMethodDto
 */
export interface GetUserOrderDetailPaymentMethodDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserOrderDetailPaymentMethodDto
     */
    'paymentType'?: GetUserOrderDetailPaymentMethodDtoPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetUserOrderDetailPaymentMethodDto
     */
    'paymentDateTime'?: string;
    /**
     * 
     * @type {GetUserOrderDetailPaymentMethodCardDto}
     * @memberof GetUserOrderDetailPaymentMethodDto
     */
    'card'?: GetUserOrderDetailPaymentMethodCardDto;
}

export const GetUserOrderDetailPaymentMethodDtoPaymentTypeEnum = {
    Card: 'CARD',
    VirtualAccount: 'VIRTUAL_ACCOUNT',
    EasyPay: 'EASY_PAY',
    CellPhone: 'CELL_PHONE',
    AccountTransfer: 'ACCOUNT_TRANSFER'
} as const;

export type GetUserOrderDetailPaymentMethodDtoPaymentTypeEnum = typeof GetUserOrderDetailPaymentMethodDtoPaymentTypeEnum[keyof typeof GetUserOrderDetailPaymentMethodDtoPaymentTypeEnum];

/**
 * 
 * @export
 * @interface GetUserOrderDetailRes
 */
export interface GetUserOrderDetailRes {
    /**
     * 
     * @type {string}
     * @memberof GetUserOrderDetailRes
     */
    'orderNumber'?: string;
    /**
     * 
     * @type {OrderDeliveryInfoVo}
     * @memberof GetUserOrderDetailRes
     */
    'deliveryAddress'?: OrderDeliveryInfoVo;
    /**
     * 
     * @type {Array<GetUserOrderDetailBrandDto>}
     * @memberof GetUserOrderDetailRes
     */
    'brandList'?: Array<GetUserOrderDetailBrandDto>;
    /**
     * 
     * @type {GetUserOrderDetailPaymentDto}
     * @memberof GetUserOrderDetailRes
     */
    'payment'?: GetUserOrderDetailPaymentDto;
    /**
     * 
     * @type {string}
     * @memberof GetUserOrderDetailRes
     */
    'createdDate'?: string;
}
/**
 * 
 * @export
 * @interface GetUserOrderListOrderDto
 */
export interface GetUserOrderListOrderDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserOrderListOrderDto
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserOrderListOrderDto
     */
    'orderNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserOrderListOrderDto
     */
    'createDate'?: string;
    /**
     * 
     * @type {Array<UserOrderItemDetailDto>}
     * @memberof GetUserOrderListOrderDto
     */
    'orderItemList'?: Array<UserOrderItemDetailDto>;
}
/**
 * 
 * @export
 * @interface GetUserOrderListRes
 */
export interface GetUserOrderListRes {
    /**
     * 
     * @type {Array<GetUserOrderListOrderDto>}
     * @memberof GetUserOrderListRes
     */
    'orderList'?: Array<GetUserOrderListOrderDto>;
}
/**
 * 
 * @export
 * @interface GetValidateResDto
 */
export interface GetValidateResDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetValidateResDto
     */
    'available'?: boolean;
}
/**
 * 
 * @export
 * @interface InitUserReq
 */
export interface InitUserReq {
    /**
     * 
     * @type {string}
     * @memberof InitUserReq
     */
    'firebaseToken'?: string;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'notificationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationType'?: NotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notifyDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'notificationRead'?: boolean;
}

export const NotificationDtoNotificationTypeEnum = {
    InquiryReplyReceived: 'INQUIRY_REPLY_RECEIVED',
    CouponIssued: 'COUPON_ISSUED',
    LimitedItemOpened: 'LIMITED_ITEM_OPENED'
} as const;

export type NotificationDtoNotificationTypeEnum = typeof NotificationDtoNotificationTypeEnum[keyof typeof NotificationDtoNotificationTypeEnum];

/**
 * 
 * @export
 * @interface OrderDeliveryInfoVo
 */
export interface OrderDeliveryInfoVo {
    /**
     * 
     * @type {DeliveryAddressVo}
     * @memberof OrderDeliveryInfoVo
     */
    'deliveryAddress'?: DeliveryAddressVo;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryInfoVo
     */
    'deliveryMemo'?: string;
}
/**
 * 
 * @export
 * @interface PaymentOrderReq
 */
export interface PaymentOrderReq {
    /**
     * 
     * @type {string}
     * @memberof PaymentOrderReq
     */
    'paymentKey'?: string;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'brandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'productImageUrl'?: string;
    /**
     * 
     * @type {ProductDtoProductYear}
     * @memberof ProductDto
     */
    'productYear'?: ProductDtoProductYear;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'productSeason'?: ProductDtoProductSeasonEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'originalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'finalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'discountPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'additionalDiscountPercent'?: number;
}

export const ProductDtoProductSeasonEnum = {
    Ss: 'SS',
    Fw: 'FW'
} as const;

export type ProductDtoProductSeasonEnum = typeof ProductDtoProductSeasonEnum[keyof typeof ProductDtoProductSeasonEnum];

/**
 * 
 * @export
 * @interface ProductDtoProductYear
 */
export interface ProductDtoProductYear {
    /**
     * 
     * @type {number}
     * @memberof ProductDtoProductYear
     */
    'value'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDtoProductYear
     */
    'leap'?: boolean;
}
/**
 * 
 * @export
 * @interface ReplyFashionStyleAssessmentReq
 */
export interface ReplyFashionStyleAssessmentReq {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReplyFashionStyleAssessmentReq
     */
    'likeFashionStyleIdList'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReplyFashionStyleAssessmentReq
     */
    'loveFashionStyleIdList'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReplyFashionStyleAssessmentReq
     */
    'disLikeFashionStyleIdList'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ReplyFashionStyleAssessmentRes
 */
export interface ReplyFashionStyleAssessmentRes {
    /**
     * 
     * @type {number}
     * @memberof ReplyFashionStyleAssessmentRes
     */
    'fashionStyleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplyFashionStyleAssessmentRes
     */
    'fashionStyleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplyFashionStyleAssessmentRes
     */
    'fashionStyleImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplyFashionStyleAssessmentRes
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface SignUpUserReq
 */
export interface SignUpUserReq {
    /**
     * 
     * @type {string}
     * @memberof SignUpUserReq
     */
    'nickName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserReq
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserReq
     */
    'recommenderCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserReq
     */
    'imageId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryAddressReq
 */
export interface UpdateDeliveryAddressReq {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDeliveryAddressReq
     */
    'defaultAddress'?: boolean;
    /**
     * 
     * @type {DeliveryAddressVo}
     * @memberof UpdateDeliveryAddressReq
     */
    'deliveryAddress'?: DeliveryAddressVo;
}
/**
 * 
 * @export
 * @interface UpdateOfferReq
 */
export interface UpdateOfferReq {
    /**
     * 
     * @type {number}
     * @memberof UpdateOfferReq
     */
    'itemQuantity'?: number;
}
/**
 * 
 * @export
 * @interface UpdateUserInfoReq
 */
export interface UpdateUserInfoReq {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserInfoReq
     */
    'imageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserInfoReq
     */
    'nickName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserInfoReq
     */
    'birthDate'?: string;
}
/**
 * 
 * @export
 * @interface UploadImageRequest
 */
export interface UploadImageRequest {
    /**
     * 
     * @type {File}
     * @memberof UploadImageRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface UploadImageRes
 */
export interface UploadImageRes {
    /**
     * 
     * @type {string}
     * @memberof UploadImageRes
     */
    'imageId'?: string;
}
/**
 * 
 * @export
 * @interface UserOrderItemDetailDto
 */
export interface UserOrderItemDetailDto {
    /**
     * 
     * @type {number}
     * @memberof UserOrderItemDetailDto
     */
    'orderItemId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserOrderItemDetailDto
     */
    'orderItemStatusType'?: UserOrderItemDetailDtoOrderItemStatusTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserOrderItemDetailDto
     */
    'brandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOrderItemDetailDto
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOrderItemDetailDto
     */
    'productImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserOrderItemDetailDto
     */
    'productOptionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserOrderItemDetailDto
     */
    'itemQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserOrderItemDetailDto
     */
    'purchaseAmount'?: number;
    /**
     * 
     * @type {DeliveryVo}
     * @memberof UserOrderItemDetailDto
     */
    'delivery'?: DeliveryVo;
}

export const UserOrderItemDetailDtoOrderItemStatusTypeEnum = {
    PaymentPending: 'PAYMENT_PENDING',
    PaymentCompleted: 'PAYMENT_COMPLETED',
    PaymentFailed: 'PAYMENT_FAILED',
    PaymentCancelled: 'PAYMENT_CANCELLED',
    DeliveryProgress: 'DELIVERY_PROGRESS',
    DeliveryCompleted: 'DELIVERY_COMPLETED',
    ExchangeRequested: 'EXCHANGE_REQUESTED',
    ReturnRequested: 'RETURN_REQUESTED',
    ExchangeApproved: 'EXCHANGE_APPROVED',
    ReturnApproved: 'RETURN_APPROVED',
    ExchangeRejected: 'EXCHANGE_REJECTED',
    ReturnRejected: 'RETURN_REJECTED'
} as const;

export type UserOrderItemDetailDtoOrderItemStatusTypeEnum = typeof UserOrderItemDetailDtoOrderItemStatusTypeEnum[keyof typeof UserOrderItemDetailDtoOrderItemStatusTypeEnum];


/**
 * AppBrandControllerApi - axios parameter creator
 * @export
 */
export const AppBrandControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandDetail: async (authorization: string, brandId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getBrandDetail', 'authorization', authorization)
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('getBrandDetail', 'brandId', brandId)
            const localVarPath = `/app/brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppBrandControllerApi - functional programming interface
 * @export
 */
export const AppBrandControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppBrandControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandDetail(authorization: string, brandId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBrandDetailRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandDetail(authorization, brandId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppBrandControllerApi.getBrandDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppBrandControllerApi - factory interface
 * @export
 */
export const AppBrandControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppBrandControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandDetail(authorization: string, brandId: number, options?: any): AxiosPromise<GetBrandDetailRes> {
            return localVarFp.getBrandDetail(authorization, brandId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppBrandControllerApi - object-oriented interface
 * @export
 * @class AppBrandControllerApi
 * @extends {BaseAPI}
 */
export class AppBrandControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {number} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppBrandControllerApi
     */
    public getBrandDetail(authorization: string, brandId: number, options?: RawAxiosRequestConfig) {
        return AppBrandControllerApiFp(this.configuration).getBrandDetail(authorization, brandId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppFashionStyleControllerApi - axios parameter creator
 * @export
 */
export const AppFashionStyleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fashionStyleProductList: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('fashionStyleProductList', 'authorization', authorization)
            const localVarPath = `/app/fashion-style/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fashionStyleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFashionStyle: async (fashionStyleId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fashionStyleId' is not null or undefined
            assertParamExists('getFashionStyle', 'fashionStyleId', fashionStyleId)
            const localVarPath = `/app/fashion-style/{fashionStyleId}`
                .replace(`{${"fashionStyleId"}}`, encodeURIComponent(String(fashionStyleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetFashionStyleAssessmentGenderTypeEnum} genderType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFashionStyleAssessment: async (genderType: GetFashionStyleAssessmentGenderTypeEnum, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genderType' is not null or undefined
            assertParamExists('getFashionStyleAssessment', 'genderType', genderType)
            const localVarPath = `/app/fashion-style/assessment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (genderType !== undefined) {
                localVarQueryParameter['genderType'] = genderType;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReplyFashionStyleAssessmentReq} replyFashionStyleAssessmentReq 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replyFashionStyleAssessment: async (replyFashionStyleAssessmentReq: ReplyFashionStyleAssessmentReq, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'replyFashionStyleAssessmentReq' is not null or undefined
            assertParamExists('replyFashionStyleAssessment', 'replyFashionStyleAssessmentReq', replyFashionStyleAssessmentReq)
            const localVarPath = `/app/fashion-style/assessment/reply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replyFashionStyleAssessmentReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppFashionStyleControllerApi - functional programming interface
 * @export
 */
export const AppFashionStyleControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppFashionStyleControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fashionStyleProductList(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFashionStyleProductListRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fashionStyleProductList(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppFashionStyleControllerApi.fashionStyleProductList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} fashionStyleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFashionStyle(fashionStyleId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFashionStyleRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFashionStyle(fashionStyleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppFashionStyleControllerApi.getFashionStyle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetFashionStyleAssessmentGenderTypeEnum} genderType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFashionStyleAssessment(genderType: GetFashionStyleAssessmentGenderTypeEnum, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFashionStyleAssessmentRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFashionStyleAssessment(genderType, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppFashionStyleControllerApi.getFashionStyleAssessment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReplyFashionStyleAssessmentReq} replyFashionStyleAssessmentReq 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replyFashionStyleAssessment(replyFashionStyleAssessmentReq: ReplyFashionStyleAssessmentReq, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplyFashionStyleAssessmentRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replyFashionStyleAssessment(replyFashionStyleAssessmentReq, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppFashionStyleControllerApi.replyFashionStyleAssessment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppFashionStyleControllerApi - factory interface
 * @export
 */
export const AppFashionStyleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppFashionStyleControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fashionStyleProductList(authorization: string, options?: any): AxiosPromise<GetFashionStyleProductListRes> {
            return localVarFp.fashionStyleProductList(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fashionStyleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFashionStyle(fashionStyleId: number, options?: any): AxiosPromise<GetFashionStyleRes> {
            return localVarFp.getFashionStyle(fashionStyleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetFashionStyleAssessmentGenderTypeEnum} genderType 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFashionStyleAssessment(genderType: GetFashionStyleAssessmentGenderTypeEnum, authorization?: string, options?: any): AxiosPromise<GetFashionStyleAssessmentRes> {
            return localVarFp.getFashionStyleAssessment(genderType, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReplyFashionStyleAssessmentReq} replyFashionStyleAssessmentReq 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replyFashionStyleAssessment(replyFashionStyleAssessmentReq: ReplyFashionStyleAssessmentReq, authorization?: string, options?: any): AxiosPromise<ReplyFashionStyleAssessmentRes> {
            return localVarFp.replyFashionStyleAssessment(replyFashionStyleAssessmentReq, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppFashionStyleControllerApi - object-oriented interface
 * @export
 * @class AppFashionStyleControllerApi
 * @extends {BaseAPI}
 */
export class AppFashionStyleControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppFashionStyleControllerApi
     */
    public fashionStyleProductList(authorization: string, options?: RawAxiosRequestConfig) {
        return AppFashionStyleControllerApiFp(this.configuration).fashionStyleProductList(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fashionStyleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppFashionStyleControllerApi
     */
    public getFashionStyle(fashionStyleId: number, options?: RawAxiosRequestConfig) {
        return AppFashionStyleControllerApiFp(this.configuration).getFashionStyle(fashionStyleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetFashionStyleAssessmentGenderTypeEnum} genderType 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppFashionStyleControllerApi
     */
    public getFashionStyleAssessment(genderType: GetFashionStyleAssessmentGenderTypeEnum, authorization?: string, options?: RawAxiosRequestConfig) {
        return AppFashionStyleControllerApiFp(this.configuration).getFashionStyleAssessment(genderType, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReplyFashionStyleAssessmentReq} replyFashionStyleAssessmentReq 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppFashionStyleControllerApi
     */
    public replyFashionStyleAssessment(replyFashionStyleAssessmentReq: ReplyFashionStyleAssessmentReq, authorization?: string, options?: RawAxiosRequestConfig) {
        return AppFashionStyleControllerApiFp(this.configuration).replyFashionStyleAssessment(replyFashionStyleAssessmentReq, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetFashionStyleAssessmentGenderTypeEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Any: 'ANY'
} as const;
export type GetFashionStyleAssessmentGenderTypeEnum = typeof GetFashionStyleAssessmentGenderTypeEnum[keyof typeof GetFashionStyleAssessmentGenderTypeEnum];


/**
 * AppHomeControllerApi - axios parameter creator
 * @export
 */
export const AppHomeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHome: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getHome', 'authorization', authorization)
            const localVarPath = `/app/home`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationList: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getNotificationList', 'authorization', authorization)
            const localVarPath = `/app/home/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotification: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('readAllNotification', 'authorization', authorization)
            const localVarPath = `/app/home/notifications/read-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification: async (authorization: string, notificationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('readNotification', 'authorization', authorization)
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('readNotification', 'notificationId', notificationId)
            const localVarPath = `/app/home/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (authorization: string, q: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('search', 'authorization', authorization)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('search', 'q', q)
            const localVarPath = `/app/home/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRecent: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('searchRecent', 'authorization', authorization)
            const localVarPath = `/app/home/search/recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppHomeControllerApi - functional programming interface
 * @export
 */
export const AppHomeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppHomeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHome(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHome(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppHomeControllerApi.getHome']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationList(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeNotificationRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationList(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppHomeControllerApi.getNotificationList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAllNotification(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAllNotification(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppHomeControllerApi.readAllNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNotification(authorization: string, notificationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNotification(authorization, notificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppHomeControllerApi.readNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(authorization: string, q: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeSearchRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(authorization, q, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppHomeControllerApi.search']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRecent(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeSearchRecentRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRecent(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppHomeControllerApi.searchRecent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppHomeControllerApi - factory interface
 * @export
 */
export const AppHomeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppHomeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHome(authorization: string, options?: any): AxiosPromise<GetHomeRes> {
            return localVarFp.getHome(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationList(authorization: string, options?: any): AxiosPromise<GetHomeNotificationRes> {
            return localVarFp.getNotificationList(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotification(authorization: string, options?: any): AxiosPromise<object> {
            return localVarFp.readAllNotification(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification(authorization: string, notificationId: number, options?: any): AxiosPromise<object> {
            return localVarFp.readNotification(authorization, notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(authorization: string, q: string, options?: any): AxiosPromise<GetHomeSearchRes> {
            return localVarFp.search(authorization, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRecent(authorization: string, options?: any): AxiosPromise<GetHomeSearchRecentRes> {
            return localVarFp.searchRecent(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppHomeControllerApi - object-oriented interface
 * @export
 * @class AppHomeControllerApi
 * @extends {BaseAPI}
 */
export class AppHomeControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppHomeControllerApi
     */
    public getHome(authorization: string, options?: RawAxiosRequestConfig) {
        return AppHomeControllerApiFp(this.configuration).getHome(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppHomeControllerApi
     */
    public getNotificationList(authorization: string, options?: RawAxiosRequestConfig) {
        return AppHomeControllerApiFp(this.configuration).getNotificationList(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppHomeControllerApi
     */
    public readAllNotification(authorization: string, options?: RawAxiosRequestConfig) {
        return AppHomeControllerApiFp(this.configuration).readAllNotification(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppHomeControllerApi
     */
    public readNotification(authorization: string, notificationId: number, options?: RawAxiosRequestConfig) {
        return AppHomeControllerApiFp(this.configuration).readNotification(authorization, notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {string} q 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppHomeControllerApi
     */
    public search(authorization: string, q: string, options?: RawAxiosRequestConfig) {
        return AppHomeControllerApiFp(this.configuration).search(authorization, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppHomeControllerApi
     */
    public searchRecent(authorization: string, options?: RawAxiosRequestConfig) {
        return AppHomeControllerApiFp(this.configuration).searchRecent(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppPaymentControllerApi - axios parameter creator
 * @export
 */
export const AppPaymentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderNumber 
         * @param {PaymentOrderReq} paymentOrderReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payment: async (orderNumber: string, paymentOrderReq: PaymentOrderReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('payment', 'orderNumber', orderNumber)
            // verify required parameter 'paymentOrderReq' is not null or undefined
            assertParamExists('payment', 'paymentOrderReq', paymentOrderReq)
            const localVarPath = `/app/payment/{orderNumber}`
                .replace(`{${"orderNumber"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentOrderReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentVoid: async (orderNumber: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('paymentVoid', 'orderNumber', orderNumber)
            const localVarPath = `/app/payment/{orderNumber}/void`
                .replace(`{${"orderNumber"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppPaymentControllerApi - functional programming interface
 * @export
 */
export const AppPaymentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppPaymentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} orderNumber 
         * @param {PaymentOrderReq} paymentOrderReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payment(orderNumber: string, paymentOrderReq: PaymentOrderReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payment(orderNumber, paymentOrderReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppPaymentControllerApi.payment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentVoid(orderNumber: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentVoid(orderNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppPaymentControllerApi.paymentVoid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppPaymentControllerApi - factory interface
 * @export
 */
export const AppPaymentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppPaymentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} orderNumber 
         * @param {PaymentOrderReq} paymentOrderReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payment(orderNumber: string, paymentOrderReq: PaymentOrderReq, options?: any): AxiosPromise<object> {
            return localVarFp.payment(orderNumber, paymentOrderReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentVoid(orderNumber: string, options?: any): AxiosPromise<object> {
            return localVarFp.paymentVoid(orderNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppPaymentControllerApi - object-oriented interface
 * @export
 * @class AppPaymentControllerApi
 * @extends {BaseAPI}
 */
export class AppPaymentControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} orderNumber 
     * @param {PaymentOrderReq} paymentOrderReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPaymentControllerApi
     */
    public payment(orderNumber: string, paymentOrderReq: PaymentOrderReq, options?: RawAxiosRequestConfig) {
        return AppPaymentControllerApiFp(this.configuration).payment(orderNumber, paymentOrderReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPaymentControllerApi
     */
    public paymentVoid(orderNumber: string, options?: RawAxiosRequestConfig) {
        return AppPaymentControllerApiFp(this.configuration).paymentVoid(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppProductControllerApi - axios parameter creator
 * @export
 */
export const AppProductControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {CreateOffersProductReq} createOffersProductReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer: async (authorization: string, productId: number, createOffersProductReq: CreateOffersProductReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createOffer', 'authorization', authorization)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createOffer', 'productId', productId)
            // verify required parameter 'createOffersProductReq' is not null or undefined
            assertParamExists('createOffer', 'createOffersProductReq', createOffersProductReq)
            const localVarPath = `/app/product/{productId}/create-offers`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOffersProductReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {CreateOrderProductReq} createOrderProductReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder1: async (authorization: string, productId: number, createOrderProductReq: CreateOrderProductReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createOrder1', 'authorization', authorization)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createOrder1', 'productId', productId)
            // verify required parameter 'createOrderProductReq' is not null or undefined
            assertParamExists('createOrder1', 'createOrderProductReq', createOrderProductReq)
            const localVarPath = `/app/product/{productId}/create-order`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderProductReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetail: async (authorization: string, productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getProductDetail', 'authorization', authorization)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductDetail', 'productId', productId)
            const localVarPath = `/app/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppProductControllerApi - functional programming interface
 * @export
 */
export const AppProductControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppProductControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {CreateOffersProductReq} createOffersProductReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffer(authorization: string, productId: number, createOffersProductReq: CreateOffersProductReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffer(authorization, productId, createOffersProductReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppProductControllerApi.createOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {CreateOrderProductReq} createOrderProductReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder1(authorization: string, productId: number, createOrderProductReq: CreateOrderProductReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderProductRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder1(authorization, productId, createOrderProductReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppProductControllerApi.createOrder1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDetail(authorization: string, productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductDetailRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDetail(authorization, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppProductControllerApi.getProductDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppProductControllerApi - factory interface
 * @export
 */
export const AppProductControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppProductControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {CreateOffersProductReq} createOffersProductReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(authorization: string, productId: number, createOffersProductReq: CreateOffersProductReq, options?: any): AxiosPromise<object> {
            return localVarFp.createOffer(authorization, productId, createOffersProductReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {CreateOrderProductReq} createOrderProductReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder1(authorization: string, productId: number, createOrderProductReq: CreateOrderProductReq, options?: any): AxiosPromise<CreateOrderProductRes> {
            return localVarFp.createOrder1(authorization, productId, createOrderProductReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetail(authorization: string, productId: number, options?: any): AxiosPromise<GetProductDetailRes> {
            return localVarFp.getProductDetail(authorization, productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppProductControllerApi - object-oriented interface
 * @export
 * @class AppProductControllerApi
 * @extends {BaseAPI}
 */
export class AppProductControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {number} productId 
     * @param {CreateOffersProductReq} createOffersProductReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppProductControllerApi
     */
    public createOffer(authorization: string, productId: number, createOffersProductReq: CreateOffersProductReq, options?: RawAxiosRequestConfig) {
        return AppProductControllerApiFp(this.configuration).createOffer(authorization, productId, createOffersProductReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} productId 
     * @param {CreateOrderProductReq} createOrderProductReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppProductControllerApi
     */
    public createOrder1(authorization: string, productId: number, createOrderProductReq: CreateOrderProductReq, options?: RawAxiosRequestConfig) {
        return AppProductControllerApiFp(this.configuration).createOrder1(authorization, productId, createOrderProductReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppProductControllerApi
     */
    public getProductDetail(authorization: string, productId: number, options?: RawAxiosRequestConfig) {
        return AppProductControllerApiFp(this.configuration).getProductDetail(authorization, productId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppPromotionControllerApi - axios parameter creator
 * @export
 */
export const AppPromotionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPromotionProductList: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getAllPromotionProductList', 'authorization', authorization)
            const localVarPath = `/app/promotions/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionDetail: async (authorization: string, promotionId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getPromotionDetail', 'authorization', authorization)
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('getPromotionDetail', 'promotionId', promotionId)
            const localVarPath = `/app/promotions/{promotionId}`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppPromotionControllerApi - functional programming interface
 * @export
 */
export const AppPromotionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppPromotionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPromotionProductList(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPromotionProductListRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPromotionProductList(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppPromotionControllerApi.getAllPromotionProductList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionDetail(authorization: string, promotionId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromotionDetailRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionDetail(authorization, promotionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppPromotionControllerApi.getPromotionDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppPromotionControllerApi - factory interface
 * @export
 */
export const AppPromotionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppPromotionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPromotionProductList(authorization: string, options?: any): AxiosPromise<GetAllPromotionProductListRes> {
            return localVarFp.getAllPromotionProductList(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} promotionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionDetail(authorization: string, promotionId: number, options?: any): AxiosPromise<GetPromotionDetailRes> {
            return localVarFp.getPromotionDetail(authorization, promotionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppPromotionControllerApi - object-oriented interface
 * @export
 * @class AppPromotionControllerApi
 * @extends {BaseAPI}
 */
export class AppPromotionControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPromotionControllerApi
     */
    public getAllPromotionProductList(authorization: string, options?: RawAxiosRequestConfig) {
        return AppPromotionControllerApiFp(this.configuration).getAllPromotionProductList(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} promotionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppPromotionControllerApi
     */
    public getPromotionDetail(authorization: string, promotionId: number, options?: RawAxiosRequestConfig) {
        return AppPromotionControllerApiFp(this.configuration).getPromotionDetail(authorization, promotionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppSignupControllerApi - axios parameter creator
 * @export
 */
export const AppSignupControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary          User가 앱을 삭제 했다가 다시 업로드 했을 경우, 로그아웃 했다가 다시 로그인 했을 경우         init 후 -> Signup     
         * @param {string} authorization 
         * @param {InitUserReq} initUserReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        init: async (authorization: string, initUserReq: InitUserReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('init', 'authorization', authorization)
            // verify required parameter 'initUserReq' is not null or undefined
            assertParamExists('init', 'initUserReq', initUserReq)
            const localVarPath = `/app/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initUserReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {SignUpUserReq} signUpUserReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (authorization: string, signUpUserReq: SignUpUserReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('signup', 'authorization', authorization)
            // verify required parameter 'signUpUserReq' is not null or undefined
            assertParamExists('signup', 'signUpUserReq', signUpUserReq)
            const localVarPath = `/app/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpUserReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateName: async (authorization: string, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('validateName', 'authorization', authorization)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('validateName', 'name', name)
            const localVarPath = `/app/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppSignupControllerApi - functional programming interface
 * @export
 */
export const AppSignupControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppSignupControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary          User가 앱을 삭제 했다가 다시 업로드 했을 경우, 로그아웃 했다가 다시 로그인 했을 경우         init 후 -> Signup     
         * @param {string} authorization 
         * @param {InitUserReq} initUserReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async init(authorization: string, initUserReq: InitUserReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.init(authorization, initUserReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppSignupControllerApi.init']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {SignUpUserReq} signUpUserReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(authorization: string, signUpUserReq: SignUpUserReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(authorization, signUpUserReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppSignupControllerApi.signup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateName(authorization: string, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetValidateResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateName(authorization, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppSignupControllerApi.validateName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppSignupControllerApi - factory interface
 * @export
 */
export const AppSignupControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppSignupControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary          User가 앱을 삭제 했다가 다시 업로드 했을 경우, 로그아웃 했다가 다시 로그인 했을 경우         init 후 -> Signup     
         * @param {string} authorization 
         * @param {InitUserReq} initUserReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        init(authorization: string, initUserReq: InitUserReq, options?: any): AxiosPromise<object> {
            return localVarFp.init(authorization, initUserReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {SignUpUserReq} signUpUserReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(authorization: string, signUpUserReq: SignUpUserReq, options?: any): AxiosPromise<object> {
            return localVarFp.signup(authorization, signUpUserReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateName(authorization: string, name: string, options?: any): AxiosPromise<GetValidateResDto> {
            return localVarFp.validateName(authorization, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppSignupControllerApi - object-oriented interface
 * @export
 * @class AppSignupControllerApi
 * @extends {BaseAPI}
 */
export class AppSignupControllerApi extends BaseAPI {
    /**
     * 
     * @summary          User가 앱을 삭제 했다가 다시 업로드 했을 경우, 로그아웃 했다가 다시 로그인 했을 경우         init 후 -> Signup     
     * @param {string} authorization 
     * @param {InitUserReq} initUserReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSignupControllerApi
     */
    public init(authorization: string, initUserReq: InitUserReq, options?: RawAxiosRequestConfig) {
        return AppSignupControllerApiFp(this.configuration).init(authorization, initUserReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {SignUpUserReq} signUpUserReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSignupControllerApi
     */
    public signup(authorization: string, signUpUserReq: SignUpUserReq, options?: RawAxiosRequestConfig) {
        return AppSignupControllerApiFp(this.configuration).signup(authorization, signUpUserReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSignupControllerApi
     */
    public validateName(authorization: string, name: string, options?: RawAxiosRequestConfig) {
        return AppSignupControllerApiFp(this.configuration).validateName(authorization, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppUserControllerApi - axios parameter creator
 * @export
 */
export const AppUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {AddDeliveryAddressReq} addDeliveryAddressReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeliveryAddress: async (authorization: string, addDeliveryAddressReq: AddDeliveryAddressReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addDeliveryAddress', 'authorization', authorization)
            // verify required parameter 'addDeliveryAddressReq' is not null or undefined
            assertParamExists('addDeliveryAddress', 'addDeliveryAddressReq', addDeliveryAddressReq)
            const localVarPath = `/app/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDeliveryAddressReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} userAddressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryAddress: async (authorization: string, userAddressId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteDeliveryAddress', 'authorization', authorization)
            // verify required parameter 'userAddressId' is not null or undefined
            assertParamExists('deleteDeliveryAddress', 'userAddressId', userAddressId)
            const localVarPath = `/app/user/address/{userAddressId}`
                .replace(`{${"userAddressId"}}`, encodeURIComponent(String(userAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponList: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getCouponList', 'authorization', authorization)
            const localVarPath = `/app/user/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryAddress: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getDeliveryAddress', 'authorization', authorization)
            const localVarPath = `/app/user/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUserInfo', 'authorization', authorization)
            const localVarPath = `/app/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInquiry: async (authorization: string, orderItemId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUserInquiry', 'authorization', authorization)
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('getUserInquiry', 'orderItemId', orderItemId)
            const localVarPath = `/app/user/inquiries/{orderItemId}`
                .replace(`{${"orderItemId"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInquiryList: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUserInquiryList', 'authorization', authorization)
            const localVarPath = `/app/user/inquiries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralUser: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('referralUser', 'authorization', authorization)
            const localVarPath = `/app/user/referral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resignUser: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('resignUser', 'authorization', authorization)
            const localVarPath = `/app/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} userAddressId 
         * @param {UpdateDeliveryAddressReq} updateDeliveryAddressReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryAddress: async (authorization: string, userAddressId: number, updateDeliveryAddressReq: UpdateDeliveryAddressReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateDeliveryAddress', 'authorization', authorization)
            // verify required parameter 'userAddressId' is not null or undefined
            assertParamExists('updateDeliveryAddress', 'userAddressId', userAddressId)
            // verify required parameter 'updateDeliveryAddressReq' is not null or undefined
            assertParamExists('updateDeliveryAddress', 'updateDeliveryAddressReq', updateDeliveryAddressReq)
            const localVarPath = `/app/user/address/{userAddressId}`
                .replace(`{${"userAddressId"}}`, encodeURIComponent(String(userAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryAddressReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {UpdateUserInfoReq} updateUserInfoReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfo: async (authorization: string, updateUserInfoReq: UpdateUserInfoReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateUserInfo', 'authorization', authorization)
            // verify required parameter 'updateUserInfoReq' is not null or undefined
            assertParamExists('updateUserInfo', 'updateUserInfoReq', updateUserInfoReq)
            const localVarPath = `/app/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserInfoReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppUserControllerApi - functional programming interface
 * @export
 */
export const AppUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {AddDeliveryAddressReq} addDeliveryAddressReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDeliveryAddress(authorization: string, addDeliveryAddressReq: AddDeliveryAddressReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDeliveryAddress(authorization, addDeliveryAddressReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.addDeliveryAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} userAddressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeliveryAddress(authorization: string, userAddressId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeliveryAddress(authorization, userAddressId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.deleteDeliveryAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCouponList(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserCouponListRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCouponList(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.getCouponList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryAddress(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDeliveryAddressRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryAddress(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.getDeliveryAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInfoRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.getUserInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInquiry(authorization: string, orderItemId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInquiryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInquiry(authorization, orderItemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.getUserInquiry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInquiryList(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInquiryListRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInquiryList(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.getUserInquiryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referralUser(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referralUser(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.referralUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resignUser(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resignUser(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.resignUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} userAddressId 
         * @param {UpdateDeliveryAddressReq} updateDeliveryAddressReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeliveryAddress(authorization: string, userAddressId: number, updateDeliveryAddressReq: UpdateDeliveryAddressReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeliveryAddress(authorization, userAddressId, updateDeliveryAddressReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.updateDeliveryAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {UpdateUserInfoReq} updateUserInfoReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserInfo(authorization: string, updateUserInfoReq: UpdateUserInfoReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserInfo(authorization, updateUserInfoReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserControllerApi.updateUserInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppUserControllerApi - factory interface
 * @export
 */
export const AppUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppUserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {AddDeliveryAddressReq} addDeliveryAddressReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeliveryAddress(authorization: string, addDeliveryAddressReq: AddDeliveryAddressReq, options?: any): AxiosPromise<object> {
            return localVarFp.addDeliveryAddress(authorization, addDeliveryAddressReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} userAddressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryAddress(authorization: string, userAddressId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteDeliveryAddress(authorization, userAddressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCouponList(authorization: string, options?: any): AxiosPromise<GetUserCouponListRes> {
            return localVarFp.getCouponList(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryAddress(authorization: string, options?: any): AxiosPromise<GetUserDeliveryAddressRes> {
            return localVarFp.getDeliveryAddress(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(authorization: string, options?: any): AxiosPromise<GetUserInfoRes> {
            return localVarFp.getUserInfo(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInquiry(authorization: string, orderItemId: number, options?: any): AxiosPromise<GetUserInquiryDto> {
            return localVarFp.getUserInquiry(authorization, orderItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInquiryList(authorization: string, options?: any): AxiosPromise<GetUserInquiryListRes> {
            return localVarFp.getUserInquiryList(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referralUser(authorization: string, options?: any): AxiosPromise<object> {
            return localVarFp.referralUser(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resignUser(authorization: string, options?: any): AxiosPromise<object> {
            return localVarFp.resignUser(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} userAddressId 
         * @param {UpdateDeliveryAddressReq} updateDeliveryAddressReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryAddress(authorization: string, userAddressId: number, updateDeliveryAddressReq: UpdateDeliveryAddressReq, options?: any): AxiosPromise<object> {
            return localVarFp.updateDeliveryAddress(authorization, userAddressId, updateDeliveryAddressReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {UpdateUserInfoReq} updateUserInfoReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfo(authorization: string, updateUserInfoReq: UpdateUserInfoReq, options?: any): AxiosPromise<object> {
            return localVarFp.updateUserInfo(authorization, updateUserInfoReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppUserControllerApi - object-oriented interface
 * @export
 * @class AppUserControllerApi
 * @extends {BaseAPI}
 */
export class AppUserControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {AddDeliveryAddressReq} addDeliveryAddressReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public addDeliveryAddress(authorization: string, addDeliveryAddressReq: AddDeliveryAddressReq, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).addDeliveryAddress(authorization, addDeliveryAddressReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} userAddressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public deleteDeliveryAddress(authorization: string, userAddressId: number, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).deleteDeliveryAddress(authorization, userAddressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public getCouponList(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).getCouponList(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public getDeliveryAddress(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).getDeliveryAddress(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public getUserInfo(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).getUserInfo(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} orderItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public getUserInquiry(authorization: string, orderItemId: number, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).getUserInquiry(authorization, orderItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public getUserInquiryList(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).getUserInquiryList(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public referralUser(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).referralUser(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public resignUser(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).resignUser(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} userAddressId 
     * @param {UpdateDeliveryAddressReq} updateDeliveryAddressReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public updateDeliveryAddress(authorization: string, userAddressId: number, updateDeliveryAddressReq: UpdateDeliveryAddressReq, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).updateDeliveryAddress(authorization, userAddressId, updateDeliveryAddressReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {UpdateUserInfoReq} updateUserInfoReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserControllerApi
     */
    public updateUserInfo(authorization: string, updateUserInfoReq: UpdateUserInfoReq, options?: RawAxiosRequestConfig) {
        return AppUserControllerApiFp(this.configuration).updateUserInfo(authorization, updateUserInfoReq, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppUserCuratingControllerApi - axios parameter creator
 * @export
 */
export const AppUserCuratingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoupon: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createCoupon', 'authorization', authorization)
            const localVarPath = `/app/user-curating/end`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCuratingProductList: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUserCuratingProductList', 'authorization', authorization)
            const localVarPath = `/app/user-curating/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startUserCurating: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('startUserCurating', 'authorization', authorization)
            const localVarPath = `/app/user-curating/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사용자가 액션을 취했으면 해당 api를 호출해야지 나중에 다시 들어왔을때 중복 노출이 되지 않음
         * @param {string} authorization 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swipingCuratedProduct: async (authorization: string, productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('swipingCuratedProduct', 'authorization', authorization)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('swipingCuratedProduct', 'productId', productId)
            const localVarPath = `/app/user-curating/products/{productId}/swiping`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppUserCuratingControllerApi - functional programming interface
 * @export
 */
export const AppUserCuratingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppUserCuratingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCoupon(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCoupon(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserCuratingControllerApi.createCoupon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCuratingProductList(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserCuratedProductListRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCuratingProductList(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserCuratingControllerApi.getUserCuratingProductList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startUserCurating(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startUserCurating(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserCuratingControllerApi.startUserCurating']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 사용자가 액션을 취했으면 해당 api를 호출해야지 나중에 다시 들어왔을때 중복 노출이 되지 않음
         * @param {string} authorization 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async swipingCuratedProduct(authorization: string, productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.swipingCuratedProduct(authorization, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserCuratingControllerApi.swipingCuratedProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppUserCuratingControllerApi - factory interface
 * @export
 */
export const AppUserCuratingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppUserCuratingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoupon(authorization: string, options?: any): AxiosPromise<object> {
            return localVarFp.createCoupon(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCuratingProductList(authorization: string, options?: any): AxiosPromise<GetUserCuratedProductListRes> {
            return localVarFp.getUserCuratingProductList(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startUserCurating(authorization: string, options?: any): AxiosPromise<object> {
            return localVarFp.startUserCurating(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사용자가 액션을 취했으면 해당 api를 호출해야지 나중에 다시 들어왔을때 중복 노출이 되지 않음
         * @param {string} authorization 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swipingCuratedProduct(authorization: string, productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.swipingCuratedProduct(authorization, productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppUserCuratingControllerApi - object-oriented interface
 * @export
 * @class AppUserCuratingControllerApi
 * @extends {BaseAPI}
 */
export class AppUserCuratingControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserCuratingControllerApi
     */
    public createCoupon(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserCuratingControllerApiFp(this.configuration).createCoupon(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserCuratingControllerApi
     */
    public getUserCuratingProductList(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserCuratingControllerApiFp(this.configuration).getUserCuratingProductList(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserCuratingControllerApi
     */
    public startUserCurating(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserCuratingControllerApiFp(this.configuration).startUserCurating(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사용자가 액션을 취했으면 해당 api를 호출해야지 나중에 다시 들어왔을때 중복 노출이 되지 않음
     * @param {string} authorization 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserCuratingControllerApi
     */
    public swipingCuratedProduct(authorization: string, productId: number, options?: RawAxiosRequestConfig) {
        return AppUserCuratingControllerApiFp(this.configuration).swipingCuratedProduct(authorization, productId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppUserOfferControllerApi - axios parameter creator
 * @export
 */
export const AppUserOfferControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {CreateOrderOfferReq} createOrderOfferReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (authorization: string, createOrderOfferReq: CreateOrderOfferReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createOrder', 'authorization', authorization)
            // verify required parameter 'createOrderOfferReq' is not null or undefined
            assertParamExists('createOrder', 'createOrderOfferReq', createOrderOfferReq)
            const localVarPath = `/app/user/offers/create-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderOfferReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer: async (authorization: string, offerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteOffer', 'authorization', authorization)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('deleteOffer', 'offerId', offerId)
            const localVarPath = `/app/user/offers/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferAll: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteOfferAll', 'authorization', authorization)
            const localVarPath = `/app/user/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferList: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getOfferList', 'authorization', authorization)
            const localVarPath = `/app/user/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} offerId 
         * @param {UpdateOfferReq} updateOfferReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer: async (authorization: string, offerId: number, updateOfferReq: UpdateOfferReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateOffer', 'authorization', authorization)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('updateOffer', 'offerId', offerId)
            // verify required parameter 'updateOfferReq' is not null or undefined
            assertParamExists('updateOffer', 'updateOfferReq', updateOfferReq)
            const localVarPath = `/app/user/offers/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOfferReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppUserOfferControllerApi - functional programming interface
 * @export
 */
export const AppUserOfferControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppUserOfferControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {CreateOrderOfferReq} createOrderOfferReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(authorization: string, createOrderOfferReq: CreateOrderOfferReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderOfferRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(authorization, createOrderOfferReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOfferControllerApi.createOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOffer(authorization: string, offerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOffer(authorization, offerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOfferControllerApi.deleteOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfferAll(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOfferAll(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOfferControllerApi.deleteOfferAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferList(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOfferListRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferList(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOfferControllerApi.getOfferList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} offerId 
         * @param {UpdateOfferReq} updateOfferReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOffer(authorization: string, offerId: number, updateOfferReq: UpdateOfferReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOffer(authorization, offerId, updateOfferReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOfferControllerApi.updateOffer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppUserOfferControllerApi - factory interface
 * @export
 */
export const AppUserOfferControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppUserOfferControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {CreateOrderOfferReq} createOrderOfferReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(authorization: string, createOrderOfferReq: CreateOrderOfferReq, options?: any): AxiosPromise<CreateOrderOfferRes> {
            return localVarFp.createOrder(authorization, createOrderOfferReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer(authorization: string, offerId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteOffer(authorization, offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferAll(authorization: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteOfferAll(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferList(authorization: string, options?: any): AxiosPromise<GetOfferListRes> {
            return localVarFp.getOfferList(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} offerId 
         * @param {UpdateOfferReq} updateOfferReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer(authorization: string, offerId: number, updateOfferReq: UpdateOfferReq, options?: any): AxiosPromise<object> {
            return localVarFp.updateOffer(authorization, offerId, updateOfferReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppUserOfferControllerApi - object-oriented interface
 * @export
 * @class AppUserOfferControllerApi
 * @extends {BaseAPI}
 */
export class AppUserOfferControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {CreateOrderOfferReq} createOrderOfferReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOfferControllerApi
     */
    public createOrder(authorization: string, createOrderOfferReq: CreateOrderOfferReq, options?: RawAxiosRequestConfig) {
        return AppUserOfferControllerApiFp(this.configuration).createOrder(authorization, createOrderOfferReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOfferControllerApi
     */
    public deleteOffer(authorization: string, offerId: number, options?: RawAxiosRequestConfig) {
        return AppUserOfferControllerApiFp(this.configuration).deleteOffer(authorization, offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOfferControllerApi
     */
    public deleteOfferAll(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserOfferControllerApiFp(this.configuration).deleteOfferAll(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOfferControllerApi
     */
    public getOfferList(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserOfferControllerApiFp(this.configuration).getOfferList(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} offerId 
     * @param {UpdateOfferReq} updateOfferReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOfferControllerApi
     */
    public updateOffer(authorization: string, offerId: number, updateOfferReq: UpdateOfferReq, options?: RawAxiosRequestConfig) {
        return AppUserOfferControllerApiFp(this.configuration).updateOffer(authorization, offerId, updateOfferReq, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppUserOrderControllerApi - axios parameter creator
 * @export
 */
export const AppUserOrderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderItem: async (authorization: string, orderId: number, orderItemId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('cancelOrderItem', 'authorization', authorization)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cancelOrderItem', 'orderId', orderId)
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('cancelOrderItem', 'orderItemId', orderItemId)
            const localVarPath = `/app/user/orders/{orderId}/orderItems/{orderItemId}/cancel`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"orderItemId"}}`, encodeURIComponent(String(orderItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {CreateUserInquiryReq} createUserInquiryReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserInquiry: async (authorization: string, orderId: number, createUserInquiryReq: CreateUserInquiryReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createUserInquiry', 'authorization', authorization)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('createUserInquiry', 'orderId', orderId)
            // verify required parameter 'createUserInquiryReq' is not null or undefined
            assertParamExists('createUserInquiry', 'createUserInquiryReq', createUserInquiryReq)
            const localVarPath = `/app/user/orders/{orderId}/inquiry`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserInquiryReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrder: async (authorization: string, orderId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUserOrder', 'authorization', authorization)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getUserOrder', 'orderId', orderId)
            const localVarPath = `/app/user/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrderList: async (authorization: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUserOrderList', 'authorization', authorization)
            const localVarPath = `/app/user/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppUserOrderControllerApi - functional programming interface
 * @export
 */
export const AppUserOrderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppUserOrderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrderItem(authorization: string, orderId: number, orderItemId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrderItem(authorization, orderId, orderItemId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOrderControllerApi.cancelOrderItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {CreateUserInquiryReq} createUserInquiryReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserInquiry(authorization: string, orderId: number, createUserInquiryReq: CreateUserInquiryReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserInquiry(authorization, orderId, createUserInquiryReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOrderControllerApi.createUserInquiry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOrder(authorization: string, orderId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserOrderDetailRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOrder(authorization, orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOrderControllerApi.getUserOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOrderList(authorization: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserOrderListRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOrderList(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppUserOrderControllerApi.getUserOrderList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppUserOrderControllerApi - factory interface
 * @export
 */
export const AppUserOrderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppUserOrderControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {number} orderItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderItem(authorization: string, orderId: number, orderItemId: number, options?: any): AxiosPromise<object> {
            return localVarFp.cancelOrderItem(authorization, orderId, orderItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {CreateUserInquiryReq} createUserInquiryReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserInquiry(authorization: string, orderId: number, createUserInquiryReq: CreateUserInquiryReq, options?: any): AxiosPromise<object> {
            return localVarFp.createUserInquiry(authorization, orderId, createUserInquiryReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrder(authorization: string, orderId: number, options?: any): AxiosPromise<GetUserOrderDetailRes> {
            return localVarFp.getUserOrder(authorization, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOrderList(authorization: string, options?: any): AxiosPromise<GetUserOrderListRes> {
            return localVarFp.getUserOrderList(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppUserOrderControllerApi - object-oriented interface
 * @export
 * @class AppUserOrderControllerApi
 * @extends {BaseAPI}
 */
export class AppUserOrderControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {number} orderId 
     * @param {number} orderItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOrderControllerApi
     */
    public cancelOrderItem(authorization: string, orderId: number, orderItemId: number, options?: RawAxiosRequestConfig) {
        return AppUserOrderControllerApiFp(this.configuration).cancelOrderItem(authorization, orderId, orderItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} orderId 
     * @param {CreateUserInquiryReq} createUserInquiryReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOrderControllerApi
     */
    public createUserInquiry(authorization: string, orderId: number, createUserInquiryReq: CreateUserInquiryReq, options?: RawAxiosRequestConfig) {
        return AppUserOrderControllerApiFp(this.configuration).createUserInquiry(authorization, orderId, createUserInquiryReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOrderControllerApi
     */
    public getUserOrder(authorization: string, orderId: number, options?: RawAxiosRequestConfig) {
        return AppUserOrderControllerApiFp(this.configuration).getUserOrder(authorization, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppUserOrderControllerApi
     */
    public getUserOrderList(authorization: string, options?: RawAxiosRequestConfig) {
        return AppUserOrderControllerApiFp(this.configuration).getUserOrderList(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommonImageControllerApi - axios parameter creator
 * @export
 */
export const CommonImageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage: async (imageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('getImage', 'imageId', imageId)
            const localVarPath = `/common/images/public/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 리턴 값은 imageId
         * @param {string} authorization 
         * @param {UploadImageRequest} [uploadImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (authorization: string, uploadImageRequest?: UploadImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('uploadImage', 'authorization', authorization)
            const localVarPath = `/common/images/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonImageControllerApi - functional programming interface
 * @export
 */
export const CommonImageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonImageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImage(imageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImage(imageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonImageControllerApi.getImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 리턴 값은 imageId
         * @param {string} authorization 
         * @param {UploadImageRequest} [uploadImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(authorization: string, uploadImageRequest?: UploadImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(authorization, uploadImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonImageControllerApi.uploadImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommonImageControllerApi - factory interface
 * @export
 */
export const CommonImageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonImageControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(imageId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 리턴 값은 imageId
         * @param {string} authorization 
         * @param {UploadImageRequest} [uploadImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(authorization: string, uploadImageRequest?: UploadImageRequest, options?: any): AxiosPromise<UploadImageRes> {
            return localVarFp.uploadImage(authorization, uploadImageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonImageControllerApi - object-oriented interface
 * @export
 * @class CommonImageControllerApi
 * @extends {BaseAPI}
 */
export class CommonImageControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} imageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonImageControllerApi
     */
    public getImage(imageId: string, options?: RawAxiosRequestConfig) {
        return CommonImageControllerApiFp(this.configuration).getImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 리턴 값은 imageId
     * @param {string} authorization 
     * @param {UploadImageRequest} [uploadImageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonImageControllerApi
     */
    public uploadImage(authorization: string, uploadImageRequest?: UploadImageRequest, options?: RawAxiosRequestConfig) {
        return CommonImageControllerApiFp(this.configuration).uploadImage(authorization, uploadImageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



